body {
  background: #277fa1;
  font-family: Montserrat, sans-serif;
}

header {
  flex-direction: column;
  width: 100vw;
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.status {
  color: #f8f8f8;
  justify-content: center;
  align-items: center;
  height: 80%;
  font-size: 30px;
  display: flex;
  position: relative;
}

.loading {
  color: #f8f8f8;
  justify-content: center;
  align-items: center;
  height: 80%;
  font-size: 30px;
  animation: 2s infinite pulse;
  display: flex;
  position: relative;
}

@keyframes pulse {
  0% {
    transform: scale(.95);
  }

  70% {
    transform: scale(1);
  }

  100% {
    transform: scale(.95);
  }
}

.add {
  text-shadow: 0 0 1px #0000004d;
  color: #247291;
  cursor: pointer;
  background-color: #facf5a;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  justify-content: center;
  padding: 10px;
  font-weight: 600;
  display: flex;
}

header nav {
  background-color: #247291;
}

header nav ul {
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding: 15px;
  list-style: none;
  display: flex;
}

header nav li {
  color: #fefefe;
  cursor: pointer;
  font-weight: 400;
}

.icon-list {
  text-align: center;
}

.container {
  flex-direction: column;
  margin-top: 15px;
  display: flex;
  overflow: hidden;
}

.list-item {
  cursor: pointer;
  background-color: #fff;
  border-radius: 30px;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  display: flex;
  overflow: hidden;
  box-shadow: inset 0 0 2px #0003;
}

.check-list {
  border: 1px solid #247291;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  width: 1px;
  height: 1px;
  margin-left: 15px;
  padding: 10px;
  display: flex;
}

.check-list:active {
  background-color: #2b89ae;
}

.completed {
  background-color: #2b89ae;
  border-radius: 50%;
  padding: 10px;
}

.list-title {
  flex: 1;
  padding-left: 5px;
}

.delete-task {
  padding-right: 20px;
  display: flex;
}

.add-new {
  vertical-align: middle;
  flex-direction: column;
  justify-content: center;
  height: 90%;
  margin: 5px;
  display: none;
}

.add-new .title {
  color: #facf5a;
}

.add-form {
  flex-direction: column;
  display: flex;
}

.add-form label {
  color: #f8f8f8;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
}

.add-form input, .add-form textarea {
  border: none;
  border-radius: 30px;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;
  padding-left: 15px;
  font-size: 15px;
}

.add-submit {
  color: #247291;
  cursor: pointer;
  background-color: #facf5a;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-weight: 600;
}

@media screen and (width >= 1050px) {
  header {
    left: 50%;
    right: unset;
    border-radius: 5px;
    flex-direction: column;
    width: 30vw;
    margin-bottom: 50px;
    display: flex;
    position: fixed;
    transform: translate(-50%);
  }

  nav {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  .add {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    transform: translate(0, 110px);
  }

  main {
    max-height: 80vh;
    overflow: scroll;
  }

  .container, .add-new {
    margin: 1% 30%;
  }
}
/*# sourceMappingURL=index.b022d53a.css.map */
